/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import retry from 'async-retry'
import _ from 'lodash'

import { allApi } from 'src/utils/axios'
import { authApi } from 'src/api/AuthApi'
import { clientApi } from 'src/api/clientApi'
import toClientPassword from 'src/lib/toClientPassword'
import logger from 'src/lib/logger'
import { DEFAULT_SCENARIO } from 'src/constants'
import { useStorage } from 'src/redux/store'
import SocialAccountProvider from 'src/models/SocialAccountProvider'
import { openPopup, runPopup } from 'src/utils/modalWindow'

const STORE_AUTH_SOURCE = {
  INIT: 'init',
  UNAUTHORIZED: 'unauthorized',
  LOGIN: 'login',
  SIGN_UP: 'sign_up',
  RESET_PASSWORD: 'reset_password',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
}

const StorageKeys = {
  token: 'token',
  refreshToken: 'refreshToken',
}

const initialState = {
  isAuthenticated: false
}

export const AuthContext = createContext(initialState)

export const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate()

  const getToken = () => localStorage.getItem(StorageKeys.token)
  const getRefreshToken = () => localStorage.getItem(StorageKeys.refreshToken)

  const storedToken = getToken()

  const [isInited, setIsInited] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(!!storedToken)
  if (storedToken) {
    allApi.forEach(api => api.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`)
  }

  const [user, setUser] = useState()
  const [userTasks, setUserTasks] = useState([])

  const reduxStorage = useStorage()

  const storeAuthResponse = async (data, source) => {
    logger.setDefaultInfo({
      user_id: data.user?.id,
      is_test_user: data.user?.is_test_user,
    })

    logger.info(`[AuthContext] storeAuthResponse id: ${data.user?.id} reason: ${source}`)

    if (!data.token) { return }

    localStorage.setItem(StorageKeys.token, data.token)
    localStorage.setItem(StorageKeys.refreshToken, data.refreshToken)
    setUser(data.user)
    setUserTasks(data.user.tasks)
    allApi.forEach(api => api.defaults.headers.common['Authorization'] = `Bearer ${data.token}`)

    await reduxStorage.loadInitData()
    setIsAuthenticated(true)
  }

  const resetPassword = async (email, update_token, password) => {
    const { data } = await authApi.resetPassword(email, update_token, password)
    await storeAuthResponse(data, STORE_AUTH_SOURCE.RESET_PASSWORD)
  }

  const signUp = async (email, password, name, birthdate, parentEmail, sourceTracking) => {
    const { data } = await authApi.signUp({
      email,
      hashedPassword: toClientPassword(password),
      appLanguage: 'en',
      name,
      birthdate,
      primaryContact: 'user',
      parentEmail,
      sourceTracking,
    })
    await storeAuthResponse(data, STORE_AUTH_SOURCE.SIGN_UP)
    return data
  }

  const login = async (email, password) => {
    const { data } = await authApi.login(email, toClientPassword(password))
    await storeAuthResponse(data, STORE_AUTH_SOURCE.LOGIN)
    return data
  }

  const refetchData = async (refreshToken, source) => {
    try {
      await retry(async (bail) => {
        try {
          const { data } = await authApi.refreshToken(refreshToken)
          await storeAuthResponse(data, source)
        } catch (e) {
          if (e.status === 401) {
            bail(e)
          } else {
            throw e
          }
        }
      }, { retries: 3 })
    } catch (e) {
      const isNetworkError = !e.response && !e.status
      if (isNetworkError) {
        const originalUrl = `${window.location.pathname}${window.location.search}`
        const url = `/no_internet${originalUrl !== '/' ? `?online_redirect=${encodeURIComponent(originalUrl)}` : ''}`
        navigate(url)
      } else {
        logger.error(`[AuthContext] Failed to refetch data: ${e.message}`)
        logout()
      }
    }
  }

  const updateUser = (updates) => {
    const newUser = {
      ...user,
      ...updates,
    }
    if (_.isEqual(newUser, user)) { return }
    setUser(newUser)
  }

  const updateUserTasks = (tasks) => {
    setUserTasks(tasks)
  }

  const logout = async ({ redirectPath = '/login', sendAuthEventLog = true } = {}) => {
    if (sendAuthEventLog) {
      authApi.logout()
    }

    setIsAuthenticated(false)
    allApi.forEach(api => delete api.defaults.headers.common['Authorization'])

    setUser(undefined)
    setUserTasks([])
    reduxStorage.logout()
    logger.clear()

    Object.values(StorageKeys).forEach(key => localStorage.removeItem(key))

    navigate(redirectPath, { replace: true })
  }

  const fetchUserTasks = async () => {
    const tasks = await clientApi.fetchUserTasks()
    if (!tasks) { return }
    updateUserTasks(tasks)
  }

  const getCallbackUrl = provider => `${window.location.origin}/auth/${provider}/callback`

  const getSocialAuthCode = async (provider) => {
    const popup = openPopup('') //Popup should be opened right after user action, otherway it can be blocked by browser (safari, FF)
    if (!popup) {
      return
    }

    try {
      let url
      if (provider === SocialAccountProvider.facebook) {
        url = await facebookAuthLink()
      } else if (provider === SocialAccountProvider.google) {
        url = await googleAuthLink()
      }
      if (!url) {
        throw new Error('Unknown provider')
      }
      popup.location.href = url
    } catch (error) {
      logger.info(`[AuthContext] getSocialAuthCode error: ${error}`)
      popup.close()
    }
    const config = {
      msgType: 'auth',
      popup,
    }
    // eslint-disable-next-line no-unused-vars
    const { code, state } = await runPopup(config)
    return code
  }

  const facebookAuthLink = async () => {
    const provider = SocialAccountProvider.facebook
    const state = {}
    const response = await authApi.getFacebookAuthLink(state, getCallbackUrl(provider))
    return response.data.url
  }

  const signUpWithFacebookCode = async (code, birthdate, parentEmail, sourceTracking) => {
    const provider = SocialAccountProvider.facebook
    const { data } = await authApi.signUpWithFacebookCode(code, getCallbackUrl(provider), birthdate, parentEmail, sourceTracking)
    await storeAuthResponse(data, STORE_AUTH_SOURCE.FACEBOOK)
    return data
  }
  
  const signInWithFacebookCode = async (code) => {
    const provider = SocialAccountProvider.facebook
    const { data } = await authApi.signInWithFacebookCode(code, getCallbackUrl(provider))
    await storeAuthResponse(data, STORE_AUTH_SOURCE.FACEBOOK)
    return data
  }

  const linkWithFacebookCode = async (code) => {
    const provider = SocialAccountProvider.facebook
    return await authApi.linkWithFacebookCode(code, getCallbackUrl(provider))
  }

  const googleAuthLink = async () => {
    const provider = SocialAccountProvider.google
    const state = {}
    const response = await authApi.getGoogleAuthLink(state, getCallbackUrl(provider))
    return response.data.url
  }

  const signUpWithGoogleCode = async (code, birthdate, parentEmail, sourceTracking) => {
    const provider = SocialAccountProvider.google
    const { data } = await authApi.signUpWithGoogleCode(code, getCallbackUrl(provider), birthdate, parentEmail, sourceTracking)
    await storeAuthResponse(data, STORE_AUTH_SOURCE.GOOGLE)
    return data
  }

  const signInWithGoogleCode = async (code) => {
    const provider = SocialAccountProvider.google
    const { data } = await authApi.signInWithGoogleCode(code, getCallbackUrl(provider))
    await storeAuthResponse(data, STORE_AUTH_SOURCE.GOOGLE)
    return data
  }

  const linkWithGoogleCode = async (code) => {
    const provider = SocialAccountProvider.google
    return await authApi.linkWithGoogleCode(code, getCallbackUrl(provider))
  }

  const unLinkWithGoogle = async () => {
    return await authApi.unLinkWithGoogle()
  }

  useEffect(() => {
    const initialize = async () => {
      //Setup auto logout
      const onError = (error) => {
        if (error.response?.status === 401) {
          if (authApi.isRefreshTokenUrl(error.config.url)) {
            logout({ sendAuthEventLog: false })
          } else if (authApi.isAuthUrl(error.config.url)) {
            throw error
          } else {
            const storedRefreshToken = getRefreshToken()
            refetchData(storedRefreshToken, STORE_AUTH_SOURCE.UNAUTHORIZED)
          }
        }

        return Promise.reject({
          status: error.response?.status,
          message: error.response?.data?.message || error.message || 'Something went wrong'
        })
      }
      allApi.forEach(api => api.interceptors.response.use(response => response, onError))

      const storedRefreshToken = getRefreshToken()
      if (storedRefreshToken) {
        await refetchData(storedRefreshToken, STORE_AUTH_SOURCE.INIT)
      }
      setIsInited(true)
    }
    initialize()
  }, [])

  const dictationScenario = user?.settings?.app?.dictation_scenario || DEFAULT_SCENARIO

  return (
    <AuthContext.Provider
      value={{
        isInited,
        isAuthenticated,
        getToken,
        getRefreshToken,
        user,
        userTasks,
        signUp,
        login,
        logout,
        resetPassword,
        updateUser,
        updateUserTasks,
        fetchUserTasks,
        dictationScenario,
        facebookAuthLink,
        googleAuthLink,
        signUpWithFacebookCode,
        signInWithFacebookCode,
        linkWithFacebookCode,
        signUpWithGoogleCode,
        signInWithGoogleCode,
        linkWithGoogleCode,
        getSocialAuthCode,
        unLinkWithGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
