import _ from 'lodash'
import { UAParser } from 'ua-parser-js'

import { api } from 'src/utils/axios'
import buildDate from 'src/build_date'
import { APP_NAME, APP_BUILD } from 'src/constants'
import { Settings, SettingsKeys } from 'src/contexts/SettingsContext'

const LOG_LEVELS = {
  debug: 'debug',
  warn: 'warn',
  error: 'error',
  info: 'info'
}

const userAgent = new UAParser().getResult()
const systemData = {
  browser: `${_.get(userAgent, 'browser.name')} ${_.get(userAgent, 'browser.version')}`,
  os: `${_.get(userAgent, 'os.name')} ${_.get(userAgent, 'os.version')}`,
  device: _.values(userAgent.device).some(x => x !== undefined) ? userAgent.device : undefined,
}

const getHTMLDate = () => document.querySelector("meta[name='date']").getAttribute('content')

class Logger {
  settings = undefined
  
  defaultInfo = undefined

  constructor(settings) {
    this.settings = settings
    this.defaultInfo = settings.get(SettingsKeys.loggerDefaultInfo)
  }

  setDefaultInfo(info) {
    this.defaultInfo = info
    settings.set(SettingsKeys.loggerDefaultInfo, info)
  }

  clear() {
    this.setDefaultInfo(null)
  }

  async makeRequest(log) {
    const data = {
      ...(this.defaultInfo || {}),
      ...(log.data || {}),
      location: window.location.pathname,
      app_build: APP_BUILD,
      build_date: buildDate,
      html_date: getHTMLDate(),
      ...systemData,
    }
    console.log(`[${log.level.toUpperCase()}]`, log.message, data)

    const isLocalDev = window.location.hostname === 'localhost'
    if (isLocalDev) {
      return
    }
    
    const ignoreList = [
      '[ErrorFallback] Loading chunk',
      'signInWithGoogleCode There is no account linked to this',
      'Initialization took too long! If you expect long running init',
      'Token expired',
      '[ProcessingHandler] error: requestId undefined error {}',
      '[SocketContext] Socket error: {}',
      'text should not be empty',
      'already exists',
      'Unauthorized',
      'email address is in use',
      'Streaming already active',
    ]
    for (const ignore of ignoreList) {
      if (log.message.includes(ignore)) {
        return
      }
    }

    const body = {
      level: log.level,
      message: log.message,
      data,
    }
    try {
      await api.post('/logs', body, { params: { app_name: APP_NAME } })
    } catch(err) {
      console.error(`Failed to send log`, err)
    }
  }

  error(message, additionalInfo = null) {
    const data = {
      level: LOG_LEVELS.error,
      message,
      data: additionalInfo
    };

    return this.makeRequest(data)
  }

  info(message, additionalInfo = null) {
    const data = {
      level: LOG_LEVELS.info,
      message,
      data: additionalInfo
    }

    return this.makeRequest(data)
  }

  debug(message, additionalInfo = null) {
    const data = {
      level: LOG_LEVELS.debug,
      message,
      data: additionalInfo
    };

    return this.makeRequest(data)
  }

  warn(message, additionalInfo = null) {
    const data = {
      level: LOG_LEVELS.warn,
      message,
      data: additionalInfo
    }

    return this.makeRequest(data)
  }
}

const settings = Settings()
const logger = new Logger(settings)

export default logger
