import React, { useEffect } from 'react'
import { Box, Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import _ from 'lodash'

import logger from 'src/lib/logger'

const useStyles = makeStyles(() => ({
  container: {
    minHeight: '100%',
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 40,
    paddingRight: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  centerText: {
    textAlign: 'center'
  }
}))

const RELOAD_KEYS = {
  CHUNK_LOAD_ERROR: 'CHUNK_LOAD_ERROR',
  NOT_A_FUNCTION_ERROR: 'NOT_A_FUNCTION_ERROR',
}

const canReload = (key, timeout = 60000) => {
  const dateString = localStorage.getItem(key)
  if (!dateString) { return true }
  return (new Date() - new Date(dateString)) > timeout
}

const reload = (key) => {
  logger.info(`[ErrorFallback] Auto reload for ${key}`)

  localStorage.setItem(key, new Date())
  window.location.reload()
}

const ErrorFallback = ({error, resetErrorBoundary}) => {
  const classes = useStyles()

  useEffect(() => {
    if (error) {
      const at = error.stack?.match(/at\s(\w*)\s/)[1]
      logger.error(`[ErrorFallback] ${error.message} at ${at}`, {
        stack: error.stack?.replace(/\n/g, '\\n'),
      })
      
      const isChunkLoadError = /Loading chunk [\d]+ failed/.test(error.message)
      if (isChunkLoadError && canReload(RELOAD_KEYS.CHUNK_LOAD_ERROR)) {
        reload(RELOAD_KEYS.CHUNK_LOAD_ERROR)
        return
      }
      const isNotAFunctionError = /is not a function at/.test(error.message)
      if (isNotAFunctionError && canReload(RELOAD_KEYS.NOT_A_FUNCTION_ERROR)) {
        reload(RELOAD_KEYS.NOT_A_FUNCTION_ERROR)
        return
      }
    }
  }, [])

  const onReloadPage = () => {
    window.location.reload()
    resetErrorBoundary()
  }

  return (
    <Box className={classes.container}>
      <Box mb={2}>
        <Typography variant='h1'>Something went wrong</Typography>
      </Box>

      <Box mb={2}>
        <Typography className={classes.centerText} variant="subtitle1">Error is: {error.message}</Typography>
      </Box>

      <Box mb={2}>
        <Typography className={classes.centerText} variant="subtitle1">Error stack is: {error.stack}</Typography>
      </Box>

      <Button variant="contained" onClick={onReloadPage}>
        <Typography>Reload page</Typography>
      </Button>
    </Box>
  )
}

export default ErrorFallback