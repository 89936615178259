import React, { useEffect } from 'react'
import {
  Dialog,
  Box,
  Button,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import useIsDesktop from 'src/hooks/useIsDesktop'
import { useSocket } from 'src/hooks/useSocket'
import { ReactComponent as Yubi } from 'src/components/images/hands_up_yubi.svg'
import { Confetti } from 'src/components/images'
import useAuth from 'src/hooks/useAuth'
import { clientApi } from 'src/api/clientApi'

const TASK_NAME = 'notify_casr_recognition_unlocked'

const StyledContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  [theme.breakpoints.up('md')]: {
    width: 530,
    height: 730,
  }
}))

const StyledContent = styled(Box)(({ theme }) => ({
  minHeight: '40%',
  background: '#EF7B43',
  color: 'white',
  paddingBottom: theme.spacing(3),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(2),
  fontFamily: theme.typography.fontFamily,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(13.375),
    paddingRight: theme.spacing(13.375),
    paddingTop: theme.spacing(2)
  }
}))

const StyledTitle = styled(Box)(({ theme }) => ({
  fontWeight: 600,
  fontSize: '22px',
  lineHeight: '32px',
  textAlign: 'center',
}))

const StyledButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(5.75),
  maxWidth: theme.spacing(20),
  borderRadius: '6px',
  width: '100%',
  fontSize: '16px',
  fontWeight: 700
}))

export const RecognitionUnlocked = () => {
  const isDesktop = useIsDesktop()
  const { userTasks, updateUserTasks } = useAuth()
  
  const openModal = (userTasks || []).includes(TASK_NAME)

  const onModalGetStarted = async () => {
    await clientApi.completeTask(TASK_NAME)
    updateUserTasks((userTasks || []).filter(t => t !== TASK_NAME))
  }

  return (
    <Dialog
      open={openModal}
      fullScreen={!isDesktop}
      sx={{
        zIndex: 1301, //Over all other popovers
      }}
      >
      <StyledContainer>
        <Box sx={theme => ({
          flexGrow: '1', display: 'flex', flexDirection: 'column', alignItems: 'center', overflow: 'hidden',
          padding: { xs: theme.spacing(4.25, 2.5, 0, 2.5), md: theme.spacing(3.375, 11.25, 0, 11.25) },
        })}>
          <Box flex='1'></Box>
          <Box position='relative' top='5px'>
            <Box sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -80%)',
            }}>
              <Confetti isDesktop={isDesktop} />
            </Box>
            <Box position='relative'><Yubi /></Box>
          </Box>
        </Box>

        <StyledContent>
          <StyledTitle mt={4.8}>Speech recognition is unlocked!</StyledTitle>
          <StyledTitle mt={2}>Voiceitt is now ready to use.</StyledTitle>
          <StyledButton variant="contained" sx={{ mt: 3.5, mb: 6.25 }} onClick={onModalGetStarted}>Get started</StyledButton>
        </StyledContent>
        
      </StyledContainer>
    </Dialog>
  )
}

export default RecognitionUnlocked
