import { API_KEY, APP_ID, LOGIN_EXTRA_INFO } from 'src/constants'
import { api, api2 } from 'src/utils/axios'
import toClientPassword from 'src/lib/toClientPassword'

const URL = {
  login: '/login',
  signUp: '/activate',
  refreshAccessToken: '/refreshAccessToken',
  forgotPassword: '/forgotPassword',
  resetPassword: '/resetPassword',
  validateResetCode: '/validateResetCode',
  logAuthEvent: '/app/logAuthEvent',
}

class AuthApi {
  login(email, hashedPassword) {
    const payload = {
      email,
      password: hashedPassword,
      ...LOGIN_EXTRA_INFO,
      app_id: APP_ID,
      api_key: API_KEY,
    }
    return api.post(URL.login, payload);
  }

  requestResetPassword(email) {
    const payload = {
      email
    }
    return api.post(URL.forgotPassword, payload)
  }

  validateResetCode(email, code) {
    const payload = {
      email,
      code
    }

    return api.post(URL.validateResetCode, payload)
  }

  resetPassword(email, update_token, password) {
    const payload = {
      update_token,
      email,
      password: toClientPassword(password),
      app_id: APP_ID,
      api_key: API_KEY
    }

    return api.post(URL.resetPassword, payload)
  }

  signUp({
    email,
    hashedPassword: password,
    appLanguage: app_language,
    name,
    birthdate,
    parentEmail: parent_email,
    primaryContact: primary_contact_type,
    sourceTracking,
  }) {
    const payload = {
      email,
      password,
      app_language,
      name,
      birthdate,
      parent_email,
      primary_contact_type,
      ...(sourceTracking && { source_tracking: sourceTracking }),
      app_id: APP_ID,
      api_key: API_KEY,
    }
    return api.post(URL.signUp, payload);
  }

  refreshToken(refreshToken) {
    const payload = {
      refreshToken,
    }
    return api.post(URL.refreshAccessToken, payload);
  }

  logAuthEvent(event) {
    const payload = { event }
    return api.post(URL.logAuthEvent, payload)
  }

  logout() {
    return this.logAuthEvent('logout')
  }

  isRefreshTokenUrl(url) {
    return URL.refreshAccessToken === url
  }

  isAuthUrl(url) {
    return Object.values(URL).includes(url)
  }

  getFacebookAuthLink(state, redirect_uri) {
    return api2.get('/app/auth/facebook/auth_url', { params: {
      state,
      redirect_uri,
    }})
  }

  signUpWithFacebookCode(code, redirect_uri, birthdate, parentEmail, sourceTracking) {
    return api2.post('/app/auth/facebook/code/sign_up', {
      code,
      redirect_uri,
      app_id: APP_ID,
      api_key: API_KEY,
      birthdate,
      parentEmail,
      ...(sourceTracking && { source_tracking: sourceTracking }),
    })
  }

  signInWithFacebookCode(code, redirect_uri) {
    return api2.post('/app/auth/facebook/code/sign_in', {
      code,
      redirect_uri,
      app_id: APP_ID,
      api_key: API_KEY,
    })
  }

  linkWithFacebookCode(code, redirect_uri) {
    return api2.post('/app/auth/facebook/code/link', {
      code,
      redirect_uri,
      app_id: APP_ID,
      api_key: API_KEY,
    })
  }

  getGoogleAuthLink(state, redirect_uri) {
    return api2.get('/app/auth/google/auth_url', { params: {
      state,
      redirect_uri,
    }})
  }

  signUpWithGoogleCode(code, redirect_uri, birthdate, parentEmail, sourceTracking) {
    return api2.post('/app/auth/google/code/sign_up', {
      code,
      redirect_uri,
      app_id: APP_ID,
      api_key: API_KEY,
      birthdate,
      parentEmail,
      ...(sourceTracking && { source_tracking: sourceTracking }),
    })
  }

  signInWithGoogleCode(code, redirect_uri) {
    return api2.post('/app/auth/google/code/sign_in', {
      code,
      redirect_uri,
      app_id: APP_ID,
      api_key: API_KEY,
    })
  }

  linkWithGoogleCode(code, redirect_uri) {
    return api2.post('/app/auth/google/code/link', {
      code,
      redirect_uri,
      app_id: APP_ID,
      api_key: API_KEY,
    })
  }

  unLinkWithGoogle() {
    return api2.post('/app/auth/google/code/unlink')
  }
}

export const authApi = new AuthApi();
