import { lazy, Suspense } from 'react'

import Home from './pages/home'
import AuthGuard from './components/Auth/AuthGuard'
import GuestGuard from './components/Auth/GuestGuard'
import LoadingScreen from './components/LoadingScreen';
import webexRoutes, { PREFIX as WEBEX_ROUTE_PREFIX } from 'src/webex/routes'

const Loadable = ({
  guard: Guard,
  component: Component,
  ...props
}) => (
  <Suspense fallback={<LoadingScreen/>}>
    {
      Guard
        ? <Guard><Component {...props}/></Guard>
        : <Component {...props}/>
    }
  </Suspense>
)

// eslint-disable-next-line no-sparse-arrays
const routes = [
  {
    path: '/reset-password',
    element: Loadable({
      component: lazy(() => import('./pages/reset-password'))
    })
  },
  {
    path: '/login',
    element: Loadable({
      guard: GuestGuard,
      component: lazy(() => import('./pages/auth/login'))
    })
  },
  {
    path: '/sign-up',
    element: Loadable({
      component: lazy(() => import('./pages/auth/sign_up'))
    })
  },
  {
    path: '/',
    element: Loadable({
      guard: AuthGuard,
      component: Home,
    })
  },
  {
    path: '/speak',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/recognition/speak')),
    })
  },
  {
    path: '/dictate',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/recognition/dictate')),
    })
  },
  {
    path: '/zoom',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/zoom'))
    })
  },
  {
    path: '/integrations/zoom',
    element: Loadable({
      component: lazy(() => import('./pages/zoom/app_instructions'))
    })
  },
  {
    path: '/menu',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/menu/fullscreen')),
    })
  },
  {
    path: '/record',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record')),
    }),
    children: [
      {
        index: true,
        element: Loadable({
          guard: AuthGuard,
          component: lazy(() => import('./pages/record/pre_training')),
        })
      },
      {
        path: '/record/personal_vocabulary',
        element: Loadable({
          guard: AuthGuard,
          component: lazy(() => import('./pages/record/personal_vocabulary/list')),
        })
      },
      {
        path: '/record/shortcut_phrases',
        element: Loadable({
          guard: AuthGuard,
          component: lazy(() => import('./pages/record/shortcut_phrases/list')),
        })
      },
    ],
  },
  {
    path: '/record/tutorial',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/tutorial')),
    })
  },
  {
    path: '/record/training/:variant',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/training')),
    })
  },
  {
    path: '/record/shortcut_phrases/:id',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/shortcut_phrases/preview')),
    })
  },
  {
    path: '/record/shortcut_phrases/:id/edit',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/shortcut_phrases/edit')),
    })
  },
  {
    path: '/record/shortcut_phrases/add',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/shortcut_phrases/add')),
    })
  },
  {
    path: '/record/notes',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/notes')),
    })
  },
  {
    path: '/record/personal_vocabulary/:id',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/personal_vocabulary/preview')),
    })
  },
  {
    path: '/record/personal_vocabulary/:id/edit',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/personal_vocabulary/edit')),
    })
  },
  {
    path: '/record/personal_vocabulary/add',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/record/personal_vocabulary/add')),
    })
  },
  {
    path: '/integrations',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/integrations')),
    })
  },
  {
    path: '/settings/audio',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/settings/mobile/settings_audio')),
    })
  },
  {
    path: '/settings/profile',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/settings/mobile/profile')),
    })
  },
  {
    path: '/settings/general',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/settings/mobile/general')),
    })
  },
  {
    path: '/settings/extra',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/settings/mobile/extra')),
    })
  },
  {
    path: '/settings/*',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/settings')),
    })
  },
  {
    path: '/mode_picker',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/mode_picker')),
    })
  },
  {
    path: '/no_internet',
    element: Loadable({
      component: lazy(() => import('./pages/no_internet')),
    })
  },
  {
    path: '/no_subscription',
    element: Loadable({
      component: lazy(() => import('./pages/no_subscription')),
    })
  },
  {
    path: WEBEX_ROUTE_PREFIX,
    children: webexRoutes,
  },
  {
    path: '/contact_us',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/contact_us')),
    })
  },
  {
    path: '/subscription',
    element: Loadable({
      guard: AuthGuard,
      component: lazy(() => import('./pages/subscription')),
    })
  },
  {
    path: '*',
    element: Loadable({
      component: lazy(() => import('./pages/404')),
    })
  },
  {
    path: '/auth/:provider/callback',
    element: Loadable({
      component: lazy(() => import('./pages/auth/social_auth_callback')),
    })
  },
]

export default routes